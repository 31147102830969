<template>
  <div class="container">
    <nav-slidemenu :navlist="navlist"></nav-slidemenu>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import { t } from '../../languages';
import navSlidemenu from "@/components/common/nav-slidemenu.vue";
// import { useRouter } from "vue-router";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: { navSlidemenu },
  setup() {
    // const router = useRouter();

    let navlist = ref([
      { id: 0, name: t("156"), path: "/achievements" },
      { id: 1, name: t("157"), path: "/versionLibrary" },
    ]);
    return {
      navlist,
    };
  },
});
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: rgba(0, 28, 49, 0.8);
}
</style>